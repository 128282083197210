// Variables

// - Default
$body-header-color: #f5f5f5;
$body-footer-color: #f5f5f5;

/* Application Overrides --------- */

table.body th.decidim-bar,
table.body td.decidim-bar{
  background-color: $body-header-color !important;
}

.cityhall-bar{
  background-color: $body-footer-color !important;
}
