#question_captcha{
  .field{
    label{
      &:first-of-type{
        margin-top: .5em;
        font-weight: 600;
        font-size: 1.5em;
      }
    }
  }
}
